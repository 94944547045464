import Vue from 'vue';
import App from './App.vue';

import router from "./router.js";

  Vue.config.productionTip = true

new Vue({
  render: h => h(App),
  router,
  // template: '<router-view></router-view>'
  
  
}).$mount('#app');

<template>
    <div class="box-layout">
      <!-- HEADLINE -->
      <section class="top-space-margin page-title-big-typography border-radius-6px lg-border-radius-0px p-0 header d-flex align-items-center" data-parallax-background-ratio="0.5">
        <div class="overlay-round opacity-extra-medium bg-blue-whale"></div>
        <div class="position-absolute top-0 overlay"></div>
        <div class="container">
          <div class="row align-items-center justify-content-center small-screen">
            <div class="col-lg-8 position-relative text-center page-title-extra-large" data-anime='{ "el": "childs", "translateY": [50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
              <svg xmlns="http://www.w3.org/2000/svg" class="careers-icon"  style="fill: white;" height="50" viewBox="0 -960 960 960" width="100"><path d="M480-400ZM80-160v-420q0-24 18-42t42-18h140v-100q0-24 18-42t42-18h280q24 0 42 18t18 42v100h140q24 0 42 18t18 42v420H80Zm220-210v60h-60v-60H140v150h680v-150H720v60h-60v-60H300ZM140-580v150h100v-60h60v60h360v-60h60v60h100v-150H140Zm200-60h280v-100H340v100Z"/></svg>
              <h3 class="m-auto text-white text-shadow-double-large fw-600 ls-minus-2px">Career</h3>
              <div class="text-white d-none d-lg-block mt-4">Welcome to GoldenPro, your trusted partner in web development,<br> where innovation meets expertise.</div>
            </div>
          </div>
        </div>
      </section>

      <!-- Join Our Team -->
      <section class="row mx-md-5 my-5 p-5 mb-2 align-items-center">
            <div class="col-md-6">
                <div class="appear anime-child anime-complete"
                     data-anime='{ "el": "childs", "translateY": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                    <img alt="logo" src="../assets/careers/hand-coding-pana.png" width="100%">
                </div>
            </div>
            <div class="col-md-6 p-sm-5">
                    <div class="align-middle"
                         data-anime='{ "el": "childs", "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 15, "easing": "easeOutQuad" }'>
                        <h3 class="fw-600 text-black context text-center text-lg-start">Join Our Team and Shape the Future of Technology!</h3>
                        <p class="lead text-md-start mt-5">
                            At GoldenPro, we believe that great talent drives innovation and success. As a leading technology-driven software development firm, AI-powered web solutions provider, we're on a mission to revolutionize the digital landscape and create impactful solutions that empower businesses and transform lives.
                        </p>
                    </div>
            </div>
        </section>

      <!-- why choose our team -->
      <section class="p-5">
            <div class="card shadow choose-golden-pro-card p-4 border border-0"
                 data-anime='{ "el": "childs", "translateY": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                <div class="card-body">
                    <div class="why-golden-pro-pic mb-5">
                        <img alt="logo" src="../assets/careers/why-golden-pro.svg" width="100%">
                    </div>
                    <h5 class="card-title fw-bold">Why Choose GoldenPro?</h5>
                    <p class="lead text-md-center mt-5 text-body-secondary">
                        When you join GoldenPro, you're not just starting a job - you're embarking on a journey of growth, learning, and endless possibilities. Here's why our team members love being part of GoldenPro:
                    </p>

                    <!-- tiled cards start -->
                    <div class="mt-5 pt-5 possibilities">
                        <div class="container text-center">
                            <div class="container text-center">
                                <div class="row mb-5 g-5">
                                    <div class="col">
                                        <div class="card mb-3 border border-0 border border-0" style="width: 250px;margin: auto;">
                                            <div class="card-body">
                                                <div>
                                                  <img alt="logo" src="../assets/careers/icons/mdi_application-cog-outline.png" width="100px">
                                                </div>

                                            </div>
                                            <p class="lead text-md-center mt-5 text-body-secondary">
                                                Innovative Projects
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="card mb-3 border border-0" style="width: 250px;margin: auto;">
                                            <div class="card-body">
                                                <div>
                                                     <img alt="logo" src="../assets/careers/icons/mdi_account-group-outline.png" width="100px">

                                                </div>

                                            </div>
                                            <p class="lead text-md-center mt-5 text-body-secondary">
                                                Collaborative Culture
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="card mb-3 border border-0" style="width: 250px;margin: auto;">
                                            <div class="card-body">
                                                <div>
                                                    <img alt="logo" src="../assets/careers/icons/mdi_account-check-outline.png" width="100px">
                                                </div>

                                            </div>
                                            <p class="lead text-md-center mt-5 text-body-secondary">
                                                Professional Development
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="card mb-3 border border-0" style="width: 250px;margin: auto;">
                                            <div class="card-body">
                                                <div>
                                                    <img alt="logo" src="../assets/careers/icons/mdi_human-capacity-increase.png" width="100px">
                                                </div>

                                            </div>
                                            <p class="lead text-md-center mt-5 text-body-secondary">
                                                Impactful Work
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="card mb-3 border border-0" style="width: 250px;margin: auto;">
                                            <div class="card-body">
                                                <div><img alt="logo" src="../assets/careers/icons/mdi_scale-balance.png" width="100px">
                                                </div>

                                            </div>
                                            <p class="lead text-md-center mt-5 text-body-secondary">
                                                Work-Life Balance
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- tiled cards end -->

                    <!-- carousel start -->
                    <div id="carouselCareers" style="display: none;" class="carousel slide mt-5 pt-5" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="10000">
                                <div class="card mb-3 border border-0 border border-0" style="width: 250px;margin: auto;">
                                            <div class="card-body">
                                                <div>
                                                  <img alt="logo" src="../assets/careers/icons/mdi_application-cog-outline.png" width="100px">
                                                </div>

                                            </div>
                                            <p class="lead text-md-center mt-5 text-body-secondary">
                                                Innovative Projects
                                            </p>
                                        </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="2000">
                                <div class="card mb-3 border border-0" style="width: 250px;margin: auto;">
                                    <div class="card-body">
                                        <div>
                                                <img alt="logo" src="../assets/careers/icons/mdi_account-group-outline.png" width="100px">

                                        </div>

                                    </div>
                                    <p class="lead text-md-center mt-5 text-body-secondary">
                                        Collaborative Culture
                                    </p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="card mb-3 border border-0" style="width: 250px;margin: auto;">
                                    <div class="card-body">
                                        <div>
                                            <img alt="logo" src="../assets/careers/icons/mdi_account-check-outline.png" width="100px">
                                        </div>

                                    </div>
                                    <p class="lead text-md-center mt-5 text-body-secondary">
                                        Professional Development
                                    </p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="col">
                                    <div class="card mb-3 border border-0" style="width: 250px;margin: auto;">
                                        <div class="card-body">
                                            <div>
                                                <img alt="logo" src="../assets/careers/icons/mdi_human-capacity-increase.png" width="100px">
                                            </div>

                                        </div>
                                        <p class="lead text-md-center mt-5 text-body-secondary">
                                            Impactful Work
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="card mb-3 border border-0" style="width: 250px;margin: auto;">
                                    <div class="card-body">
                                        <div><img alt="logo" src="../assets/careers/icons/mdi_scale-balance.png" width="100px">
                                        </div>

                                    </div>
                                    <p class="lead text-md-center mt-5 text-body-secondary">
                                        Work-Life Balance
                                    </p>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselCareers" data-bs-slide="prev">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z"/></svg>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselCareers" data-bs-slide="next">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m304-82-56-57 343-343-343-343 56-57 400 400L304-82Z"/></svg>
                        </button>
                    </div>
                 </div>
                <!-- carousel end -->

            </div>
        </section>

        <!-- Join Our Team Today -->
      <section class="row mx-md-5 my-5 p-5 mb-2 align-items-center">
            <div class="col-md-6 p-sm-5 order-1 order-lg-0">
                    <div class="align-middle">
                        <h3 class="fw-600 text-black context text-center text-lg-start">Join Our Team Today!</h3>
                        <p class="lead text-md-start mt-5">
                            Ready to take your career to the next level? Explore our current job openings and join a team that's shaping the future of technology. Whether you're a seasoned professional or a recent graduate, we're looking for passionate individuals who are eager to make an impact and drive innovation.
                        </p>
                        <p class="lead text-md-start mt-5">
                            Browse our open positions and apply today to become part of the GoldenPro family. We can't wait to welcome you aboard!
                        </p>
                    </div>
            </div>
            <div class="col-md-6 order-0 order-lg-1">
                <div class="appear anime-child anime-complete">
                    <img alt="logo" src="../assets/careers/ok-man.png" width="100%">
                </div>
            </div>

        </section>

        <!-- Open Positions -->
      <section class="container">
          <div class="align-middle">
            <h3 class="fw-600 text-black context text-center">Open Positions</h3>
          </div>
          <div class="row">
            <div class="col-6 col-lg-3" data-anime='{"opacity": [0,1], "duration": 600, "delay": 600, "staggervalue": 300, "easing": "easeOutQuad" }'>
              <div class="card mb-3 position-relative">
                <img src="../assets/careers/seniordev.png">
                <div class="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9"></div>
                <div class="lead text-md-center cards-job-title">
                  Senior Software Engineer
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-3" data-anime='{"opacity": [0,1], "duration": 600, "delay": 1200, "staggervalue": 300, "easing": "easeOutQuad" }'>
              <div class="card mb-3 position-relative">
                <img src="../assets/careers/devops.png">
                <div class="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9"></div>
                <div class="lead text-md-center cards-job-title">
                  Dev Ops
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-3 lg-pt-15px pt-0" data-anime='{"opacity": [0,1], "duration": 600, "delay": 1800, "staggervalue": 300, "easing": "easeOutQuad" }'>
              <div class="card mb-3 position-relative">
                <img src="../assets/careers/frontdev.png">
                <div class="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9"></div>
                <div class="lead text-md-center cards-job-title">
                  Front End Developer
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-3 lg-pt-15px pt-0" data-anime='{"opacity": [0,1], "duration": 600, "delay": 2400, "staggervalue": 300, "easing": "easeOutQuad" }'>
              <div class="card mb-3 position-relative">
                <img src="../assets/careers/sales.png">
                <div class="position-absolute left-0px top-0px w-100 h-100 bg-gradient-regal-blue-transparent opacity-9"></div>
                <div class="lead text-md-center cards-job-title">
                  Sales Executive
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  </template>

  <script>
  export default {
        name: 'CareersPage',
        props: {
        //   msg: String
        }
  }
  </script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .career-container{
    margin:110px 80px 80px 80px;
    height:fit-content;
    overflow:hidden;
    font-family:"plus jakarta sans";
  }
    .sec{
        margin-top: 50px;
    }

    .headline-circle-overlay{
        width: 24%;
        padding-top: 24%;
        justify-self: center;
        background-color:rgb(22,35,64,0.40);
    }

    .cards-job-title{
      position:absolute;
      bottom: 0;
      color: white;
      padding: 20px;
    }

    .circle-container{
        display: flex;
        justify-content: center;
        width: 100%;
        border: 1px solid red;
    }
   .material-icons{
        /* font-family: 'Material Icons' ; */
        font-weight: normal;
        font-style: normal;
        font-size: 42px; /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: 1tr;
        /* Support for all Webkit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
    }

    .material-symbols-outlined {
        font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
    }

    .choose-golden-pro-card{
         background-image: linear-gradient(#F7F7FF,#F4F4FB,#FBFBFE,#FFFFFF);
    }

    .why-golden-pro-pic {
        width: 50%;
        margin: auto;
    }

    .header-icon{
        margin:auto;
        height:auto;
        width:50px;
    }
    .header{
        background-image: url("../assets/careers/headline-picture.jpg");
        background-repeat: no-repeat;
        background-size:cover;
        background-position:center;
    }

    .overlay{
        width:100%;
        height:100%;
        background-image: linear-gradient(#0058A9,#002343);
        opacity: 50%;
    }
    .overlay-text-container{
        width:100%;
        height:100%;
    }
    .overlay-round{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); /* Center the element */
      width: 0;
      height: 0;
      background-color: #162340;
      opacity: 0;
      border-radius: 50%; /* Make it round */
      animation: popOut 1s forwards;
      animation-delay: 0.5s; /* Add a delay of 0.5 seconds */
    }
    @keyframes popOut {
      0% {
        width: 0;
        height: 0;
        opacity: 0;
      }
      100% {
        width: 460px;
        height: 460px;
        opacity: 0.7;
      }
    }
    .careers-text{
        font-size:24px;
        color: white;
    }

    .possibilities{
        display: block;
    }

    @media(max-width:991px){

        .overlay{
        border-radius:0px;
        }
        .overlay-round{
        width:250px;
        height:250px;
        }
      @keyframes popOut {
        0% {
          width: 0;
          height: 0;
          opacity: 0;
        }
        100% {
          width: 250px;
          height: 250px;
          opacity: 0.7;
        }
      }
        .header-icon{
        width:25px;
        padding-top:20px;
        }
        h1{
        font-size:24px;
        }
        .careers-text{
        font-size:14px;
        line-height:80%;
        }
        .mid-content .context{
        text-align:center;
        font-size:16px;
        padding:32px;
        }
        h1{
        font-size:24px;
        line-height:100%;
        }
        .careers-icon{
            width: 40px;
            height: 40px;
        }
        #carouselCareers{
            display:none
        }
    }

    @media(max-width:700px){
        .overlay-round{
            width:220px;
            height:220px;
        }
        .careers-icon{
            width: 30px;
            height: 30px;
        }
        .page-title-large{
            margin-top: 40px;
        }
        .possibilities{
            display: none;
        }
        #carouselCareers{
            display:block !important
        }
    }

    @media(max-width:500px){
        .overlay-round{
            width:200px;
            height:200px;
        }
        .page-title-large{
            margin-top: 40px;
        }
    }

    @media(max-width:320px){
        .overlay-round{
            width:190px;
            height:190px;
        }
        .page-title-large{
            margin-top: 10px;
        }
        .overlay-text-container{
            margin-top: 50px;
        }
    }
  </style>
  
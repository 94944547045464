<template>
  <div id="app">
    <MainNav/>
    <router-view></router-view>
    <MainFooter/>
  </div>
</template>

<script>
import MainNav from './components/MainNavigation.vue';
import MainFooter from './components/MainFooter.vue';

export default {
  components: {
    MainNav,
    MainFooter,
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #6F6F6F;
  overflow-x: hidden;
  font-family:"plus jakarta sans";
  /* //margin-top: 60px; */
}
span,p {
  color: #6F6F6F;
}
</style>

import Vue from "vue";
import Router from "vue-router";
import NotFound from './components/NotFound.vue';



import Services from './components/Services.vue';
import CareersPage from './components/CareersPage.vue';
import LandingPage from "./components/LandingPage.vue";
import AboutUs from "./components/AboutUs.vue";
import Policy from "./components/Policy.vue"

const routes = [
    {path:'/',name:'home',component: LandingPage},
    {path:'/services',name:'services',component: Services},
    {path:'/careers',name:'careers',component: CareersPage},
    {path:'/aboutus',name:'aboutus',component: AboutUs},
    {path:'/policy',name:'policy',component: Policy},
    {
      path: '*',
      name: 'not-found',
      component: NotFound
    }
  ];

 const router = new Router({
     mode: 'history',
    routes
  });


  Vue.use(Router);
  export default router